import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

// Home
const HomePage = lazy(() => import("./Pages/Home/Home"));

// Services
const GraphicDesignPage = lazy(() => import("./Pages/Services/GraphicDesign"));
const WebDevelopment = lazy(() => import("./Pages/Services/WebDevelopment"));
const AppDevelopment = lazy(() => import("./Pages/Services/AppDevelopment"));
const DigitalMarketing = lazy(() =>
  import("./Pages/Services/DigitalMarketing")
);

// About
const AboutPage = lazy(() => import("./Pages/About/About"));

// Contact
const ContactPage = lazy(() => import("./Pages/Contact/Contact"));

// Portfolio
const PortfolioPage = lazy(() => import("./Pages/Portfolio/Portfolio"));

// Blogs
const BlogPage = lazy(() => import("./Pages/Blog/Blog"));

const BlogCategoryPage = lazy(() => import("./Pages/Blog/BlogCategoryPage"));

const SingleBlogPage = lazy(() => import("./Pages/Blog/SingleBlogPage"));


//404 Not Found Page 

const NotFoundPage = lazy(() => import("./Pages/404"));

// Function to handle trailing slash removal
function RemoveTrailingSlash() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith("/") && location.pathname !== "/") {
      // If the URL ends with a slash (except for the root), remove the slash
      window.history.replaceState({}, "", location.pathname.slice(0, -1));
    }
  }, [location]);

  return null;
}

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Scroll to top on location change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {/* Remove trailing slash component */}
        <RemoveTrailingSlash />

        <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
          <ScrollToTopButton />
          <AnimatePresence exitBeforeEnter>
            <Suspense fallback={<></>}>
              <Routes>
                {/* Home Page */}
                <Route
                  path="/"
                  element={<HomePage style={{ "--base-color": "#00A99D" }} />}
                />

                {/* About Page */}
                <Route
                  path="about"
                  element={<AboutPage style={{ "--base-color": "#00A99D" }} />}
                />

                {/* Contact Page */}
                <Route
                  path="contact-us"
                  element={
                    <ContactPage style={{ "--base-color": "#00A99D" }} />
                  }
                />

                {/* Services Page */}
                <Route
                  path="graphic-design"
                  element={
                    <GraphicDesignPage style={{ "--base-color": "#00A99D" }} />
                  }
                />
                <Route
                  path="web-development"
                  element={
                    <WebDevelopment style={{ "--base-color": "#00A99D" }} />
                  }
                />
                <Route
                  path="app-development"
                  element={
                    <AppDevelopment style={{ "--base-color": "#00A99D" }} />
                  }
                />
                <Route
                  path="digital-marketing"
                  element={
                    <DigitalMarketing style={{ "--base-color": "#00A99D" }} />
                  }
                />

                {/* Portfolio Page */}
                <Route
                  path="portfolio"
                  element={
                    <PortfolioPage style={{ "--base-color": "#00A99D" }} />
                  }
                />

                {/* Blog Page */}
                <Route
                  path="blogs"
                  element={<BlogPage style={{ "--base-color": "#00A99D" }} />}
                />
                <Route path="category">
                  <Route
                    path=":category"
                    element={
                      <BlogCategoryPage style={{ "--base-color": "#00A99D" }} />
                    }
                  />
                  <Route path="" element={<Navigate to="/" />} />
                </Route>

                {/* Catch-all route */}
                <Route path="*" element={<Navigate to="/" />} />

                <Route path="single-blog-page" element={<SingleBlogPage style={{ "--base-color": "#00A99D" }} />}/>
                
                {/*<Route
                    path="not-found"
                    element={
                      <NotFoundPage style={{ "--base-color": "#00A99D" }} />
                    }
                  /> */}

              </Routes>
            </Suspense>
          </AnimatePresence>
        </main>
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
